<template>
  <v-card tile>
    IMAGES DETAILED
  </v-card>
</template>
<script>
export default {
  name: 'imagesDetailed'
}
</script>
